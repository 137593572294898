import React, { useEffect, useState } from "react"

import "./index.scss"
function Carousel({ general, others }) {
  const [indicators, setIndicators] = useState(0)
  useEffect(() => {
    {
      others.map(
        (data, index) =>
          data?.frontmatter?.slide && setIndicators(indicators + 2)
      )
    }
  }, [])
  return (
    <div
      id="carouselBlog"
      className="carousel slide"
      data-bs-ride="carousel"
      data-ride="carousel"
      data-interval="3000"
    >
      <div className=" carousel-indicators">
        {(function (ind) {
          for (let index = 0; index <= indicators; index += 1) {
            ind.push(
              <button
                type="button"
                data-bs-target="#carouselBlog"
                data-bs-slide-to={index}
                className={index === 0 ? "active" : ""}
                aria-current="true"
                aria-label={`Slide ${index}`}
                key={index}
              ></button>
            )
          }
          return ind
        })([])}
      </div>
      <div className="carousel-inner">
        {general && (
          <div className="carousel-item active">
            <div className="carousel-upper">
              <div className="d-flex feature">
                <i className="bi bi-star-fill"></i>
                <p>Featured article</p>
              </div>
              <p>
                <a href={`/blog/${general?.slug}`}>
                  {general?.frontmatter.title}
                </a>
              </p>
            </div>
            <a href={`/blog/${general?.slug}`}>
              <img
                width="2000"
                height="1612"
                src={
                  general?.frontmatter?.picture
                    ? general?.frontmatter?.picture
                    : "/maxapps.jpg"
                }
                data-lazy-type="image"
                className="lazy d-block w-100 carousel-image "
                alt={`IBM Maximo: ${general?.frontmatter.title}`}
                title={`IBM Maximo: ${general?.frontmatter.title}`}
              />
            </a>

            <div className="carousel-caption d-block">
              <div className="carousel-lower">
                <p>{general?.frontmatter?.description}</p>
              </div>
            </div>
          </div>
        )}
        {others.map(
          (data, index) =>
            data?.frontmatter?.slide && (
              <div className="carousel-item" key={index}>
                <div className="carousel-upper">
                  <div className="d-flex feature">
                    <i className="bi bi-star-fill"></i>
                    <p>Featured article</p>
                  </div>
                  <p>
                    <a href={`/blog/${data?.slug}`}>
                      {data?.frontmatter.title}
                    </a>
                  </p>
                </div>
                <a href={`/blog/${data?.slug}`}>
                  <img
                    width="2000"
                    height="1612"
                    src={
                      data?.frontmatter?.slidepicture
                        ? data?.frontmatter?.slidepicture
                        : data?.frontmatter?.picture
                    }
                    data-lazy-type="image"
                    className="lazy d-block w-100 carousel-image "
                    alt={`IBM Maximo: ${data?.frontmatter?.title}`}
                    title={`IBM Maximo: ${data?.frontmatter?.title}`}
                  />
                </a>

                <div className="carousel-caption d-block">
                  <div className="carousel-lower">
                    <p>{data?.frontmatter?.description}</p>
                  </div>
                </div>
              </div>
            )
        )}
        <div className="carousel-item" key={3}>
          <div className="carousel-upper">
            <div className="d-flex feature">
              <i className="bi bi-star-fill"></i>
              <p>Featured article</p>
            </div>
            <p className="maxappsworld">
              <a href={`/maximo-world/`}>Maximo world 2022</a>
            </p>
          </div>
          <a href={`/maximo-world/`}>
            <img
              width="2000"
              height="1612"
              src="/maxappsworld2.png"
              data-lazy-type="image"
              className="lazy d-block w-100 carousel-image "
            />
          </a>

          <div className="carousel-caption d-block">
            <div className="carousel-lower">
              <p>
                MaximoWorld is one of the world's most generous knowledge and
                experience sharing forums, featuring some of the world's
                best-run organizations combined with cutting-edge technology
                from IBM and other technology leaders.At MaximoWorld ,
                passionate IBM Maximo users have been gathering with their
                community For over 20 years .
              </p>
            </div>
          </div>
        </div>
      </div>
      <button
        className="carousel-control-prev"
        type="button"
        data-bs-target="#carouselBlog"
        data-bs-slide="prev"
      >
        <span
          className="visually-hidden carousel-control-prev-icon"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button
        className="carousel-control-next"
        type="button"
        data-bs-target="#carouselBlog"
        data-bs-slide="next"
      >
        <span
          className="visually-hidden  carousel-control-next-icon"
          aria-hidden="true"
        ></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  )
}

export default Carousel
