import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "./index.scss"
import ArticleItem from "../article-item"
import ArticleMail from "../../article/article-mail"
import Carousel from "./carousel"

function ArticleList({ _articlesList }) {
  let articlesList = []
  try {
    const result = useStaticQuery(graphql`
      {
        allMdx(filter: { fileAbsolutePath: { regex: "/(blog)/" } }) {
          nodes {
            frontmatter {
              title
              author
              date
              description
              general
              picture
              popular
              video
              tags
              slidepicture
              slide
            }
            slug
          }
        }
      }
    `)
    articlesList = result.allMdx.nodes
  } catch (error) {
    articlesList = _articlesList
  }
  const index = articlesList.findIndex(data => data?.frontmatter?.general)

  const general = index < 0 ? false : articlesList[index]
  const others = !general
    ? articlesList
    : articlesList.filter((data, indexDATA) => indexDATA != index)

  let pageurl = ""
  typeof window !== "undefined" && (pageurl = window.location.href)
  return (
    <main>
      <div className="blog row" id="ArticleList" data-testid="ArticleList">
        <Carousel general={general} others={others} />

        <article>
          <section className="col-xs-12 container">
            <p className="Title base c-bw-12 p-3 fwm">
              <strong>Popular articles</strong>
            </p>
          </section>
          <section className="row container">
            {others.map(
              (data, index) =>
                data?.frontmatter?.popular && (
                  <div key={index} className=" col-md-4 p-0 p-md-3">
                    <ArticleItem data={data} />
                  </div>
                )
            )}
          </section>
          <section className="col-xs-12 container">
            <p className="Title base c-bw-12 p-3 mt-2 fwm">
              <strong>Recent articles</strong>
            </p>
          </section>
          <section className="row container">
            {others.map(
              (data, index) =>
                !data?.frontmatter?.popular &&
                !data?.frontmatter.video && (
                  <div key={index} className=" col-md-4 p-0 p-md-3">
                    <ArticleItem data={data} />
                  </div>
                )
            )}
          </section>

          <section className="col-xs-12 container">
            <p className="Title base c-bw-12 p-3 mt-2 fwm">
              <strong>Videos library</strong>
            </p>
          </section>

          <section className="row container">
            {others.map(
              (data, index) =>
                data?.frontmatter?.video && (
                  <div key={index} className=" col-md-4  p-0 p-md-3">
                    <ArticleItem data={data} />
                  </div>
                )
            )}
          </section>
          <ArticleMail />
        </article>
      </div>
    </main>
  )
}

export default ArticleList
