import React from "react"

import Blog from "../components/blog"
import BlogLayout from "../components/layout/t1/blogLayout"

function BlogPage() {
  return (
    <BlogLayout>
      <Blog />
    </BlogLayout>
  )
}

export default BlogPage
