import React from "react"
import { ArticleJsonLd, GatsbySeo } from "gatsby-plugin-next-seo"

import ArticleList from "./article-list"
import "./index.scss"

function Blog() {
  return (
    <div id="Blog" data-testid="Blog">
      <GatsbySeo
        title="IBM Maximo Mobile complete guide | MAXapps Blog"
        description="Discover the IBM Maximo solutions guide on the MAXapps blog. Many concepts of creative mobile apps technologies and functionalities are available."
        openGraph={{
          url: "https://maxapps-maximo.com/blog/",
          title: "IBM Maximo Mobile complete guide",
          description:
            "Discover the IBM Maximo solutions guide on the MAXapps blog. Many concepts of creative mobile apps technologies and functionalities are available.",
          locale: "en-US",
          type: "website",
          rights: "© Copyright 2022 MAXapps Maximo Mobile",
          images: [
            {
              url: "https://maxapps-maximo.com/Maximo-Mobile-Solution-Organization.png",
            },
          ],
          author: {
            type: "@MAXapps",
            name: "@MAXapps",
            url: "https://maxapps-maximo.com/blog/",
            image:
              "https://maxapps-maximo.com/Maximo-Mobile-Solution-Organization.png",
          },
          site_name: "maxapps-maximo",
        }}
        twitter={{
          handle: "@maxpapps",
          site: "@maxpapps",
          title: "IBM Maximo Mobile complete guide",
          description:
            "Discover the IBM Maximo solutions guide on the MAXapps blog. Many concepts of creative mobile apps technologies and functionalities are available.",
          cardType: "summary_large_image",
          image:
            "https://maxapps-maximo.com/Maximo-Mobile-Solution-Organization.png",
        }}
      />
      <ArticleJsonLd authorName="@maxpapps" />

      <ArticleList />
    </div>
  )
}

export default Blog
