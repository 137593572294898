import React, { useState } from "react"
import * as Yup from "yup"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { Link } from "gatsby"

import { sendMail } from "../../../services/mail"
import LottieAnimation from "../../../animations/lottie"

import "./index.scss"
const ArticleMail = props => {
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("*Must be a valid email address")
      .max(100, "*Email must be less than 100 characters")
      .required("*This field is required"),

    note: Yup.string().required("*This field is required"),
    agree: Yup.bool().oneOf([true], "Checkbox selection is required"),
    submit: Yup.string(),
  })
  const [showSuccessMsg, setShowSuccessMsg] = useState(false)

  return (
    <>
      <div
        id="ArticleMail"
        className="ArticleMail sect justify-content-center"
        data-testid="ArticleMail"
      >
        <div className="col-12 emptydiv"></div>

        <div className="col-12 col-md-12 whitegradien">
          <div className="col-8">
            <div className=" text-center ">
              <h2>
                <strong>Anything</strong> you would like to read about our next
                article ?
              </h2>
            </div>
            <div className="form ">
              <Formik
                initialValues={{
                  email: "",
                  note: "",
                  agree: "",
                  submit: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (
                  values,
                  { setSubmitting, resetForm, setStatus, setErrors }
                ) => {
                  setSubmitting(true)

                  const res = await sendMail(values, window.location.href)

                  if (res.success) {
                    resetForm({})
                    setStatus({ success: true })
                    setShowSuccessMsg(true)
                  } else {
                    setStatus({ success: false })
                    setErrors({ submit: res.message })
                  }

                  setTimeout(() => {
                    setShowSuccessMsg(false)
                  }, 3000)

                  setSubmitting(false)
                }}
              >
                {({ isSubmitting, touched, errors }) => (
                  <Form>
                    <div className="form-input form-input-mail">
                      <Field
                        type="email"
                        name="email"
                        placeholder="Email"
                        className={`form-control ${
                          touched.email && errors.email ? "error" : null
                        } mail-input`}
                      />
                      <ErrorMessage
                        name="email"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="form-input form-input-desc">
                      <Field
                        type="text"
                        name="note"
                        component="textarea"
                        placeholder="Subject and description"
                        className={`form-control ${
                          touched.note && errors.note ? "error" : null
                        } mail-input`}
                      />
                      <ErrorMessage
                        name="note"
                        component="div"
                        className="error-message"
                      />
                    </div>
                    <div className="form-input submitsend">
                      <label className="col-8 p-2 checkoutlabel">
                        <Field
                          type="checkbox"
                          name="checked"
                          required
                          className={`${
                            touched.agree && errors.agree ? "error" : null
                          } mail-input`}
                        />
                        I agree to the{" "}
                        <Link className="link" to="/privacy-policy/">
                          Terms Of Use
                        </Link>
                        ,{" "}
                        <Link className="link" to="/terms-of-use/">
                          privacy policy
                        </Link>{" "}
                        and mail notifications
                        <ErrorMessage
                          name="agree"
                          component="div"
                          className="error-message"
                        />
                      </label>
                      <button
                        type="submit"
                        name="submit"
                        disabled={isSubmitting}
                        className="form-button submit-button btn col-4"
                      >
                        Send
                        {!isSubmitting && (
                          <i className="bi bi-arrow-right-short "></i>
                        )}
                        {isSubmitting && (
                          <div className="login-loader">
                            <LottieAnimation height={25} width={25} />
                          </div>
                        )}
                      </button>
                    </div>

                    <ErrorMessage
                      name="submit"
                      component="div"
                      className="error-message"
                    />
                    {showSuccessMsg && (
                      <div className="success-msg d-flex">
                        <i className="bi bi-envelope-fill px-2"></i>
                        <h6>
                          we have received your request, we will answer you as
                          soon as possible.
                        </h6>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ArticleMail
